.App {
  text-align: center;
}
html {
  background: #fffefe;
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

button {
  display: block;
  width: 310px;
  height: 30px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background: #222;
  border: none;
  color: #fdfdfd;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.3s;
}

button:hover {
  background: rgb(5, 98, 185);
  transition: 0.4s;
}

.App-header {
  height: auto;
  padding: 0;
  color: #949090;
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
  text-align: left;
  background: #ffffff;
}
.App-header h1 {
  padding-left: 2px;
  position: relative;
  font-size: 28px;
  font-weight: lighter;
  letter-spacing: -2px;
  font-size: 24px;
}
.header-wrapper {
  margin-left: 13%;
  top: 10px;
  position: relative;
}
.header-wrapper > img {
  width: 107px;
  position: relative;
}
.header-wrapper > * {
  display: inline-block;
}

.signature-container {
  text-align: left;
  margin-left: 35%;
}
.field-wrapper {
  text-align: left;
  width: 100%;
  /* margin: auto; */
  margin-left: 34.8%;
}
.field-wrapper:hover h5 {
  color: #7fcce6;
  transition: 0.3s;
  animation: fieldblink 0.9s infinite;
  animation-timing-function: ease-in-out;
  animation-delay: 0.3s;
}
.field-wrapper:hover p {
  color: #f10707;
  transition: 0.3s;
  animation: fieldblink 0.9s infinite;
  animation-timing-function: ease-in-out;
  animation-delay: 0.3s;
}

@keyframes fieldblink {
  0% {
    color: #fff;
  }
  50% {
    color: #222;
  }
  100% {
    color: #fff;
  }
}
.field-wrapper h5 {
  transition: 0.3s;
}

.filter {
  position: absolute;
  width: 100%;
  height: 100%;
}
.App-header ul {
  list-style: none;
  position: relative;
  border-left: 5px solid #1768aa;
  padding-bottom: 0;
  margin-bottom: 0;
  margin-left: 2%;
  border-top-left-radius: 5px;
  background: rgb(255, 255, 255);
  box-sizing: border-box;
  padding: 9px 15px;
  max-width: 605px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.App-header li {
  line-height: 170%;
  font-size: 13px;
}
.App-intr .App-title {
  font-size: 1.5em;
  color: #555;
  position: relative;
  z-index: 3;
}

.App-intro {
  font-size: large;
}

.App-intro h5 {
  padding: 0;
  margin: 9px 0px 8px 0px;
  color: #000000;
  width: 31%;
  text-align: left;
  margin-bottom: 10px;
  margin-top: 10px;
  position: relative;
  font-weight: lighter;
  text-transform: uppercase;
  font-size: 11px;
}

.App-intro input {
  width: 300px;
  height: 29px;
  text-indent: 5px;
  padding: 5px;
  border: none;
  color: #555;
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 100;
  background: #222;
  transform: skewX(1deg);
  transition: 0.3s;
  border-radius: 4px;
  color: #fdfdfd;
}
.line-one {
  position: relative;
  margin-left: 20%;
  height: 350px;
  position: absolute;
  width: 1px;
  width: 15%;
  border-left: 11px solid #4c0e0a;
  border-bottom: 11px solid #4c0e0a;
  box-sizing: border-box;
  border-bottom-left-radius: 10px;
}

iframe {
  border: 11px solid #4c0e0a;
  padding: 0;
  width: 373px;
  height: 144px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 769px) {
  .header-wrapper {
    margin-left: 2%;
  }
  .App-header ul {
    margin-left: 2.5%;
  }
  .line-one {
    margin-left: 2.5%;
    margin-top: -5px;
  }
  .signature-container {
    margin-left: 17.5%;
  }
  .field-wrapper {
    margin-left: 17.5%;
  }
  .App-intro input {
    width: 60%;
  }
  .App-header {
    min-height: 150px;
    height: auto;
    padding: 0px;
  }
}

@media (max-width: 600px) {
  .header-wrapper > img {
    margin-top: 15px;
  }

  .line-one {
    width: 0;
    margin-top: -23px;
  }
  iframe {
    padding: 0;
    transition: padding 0.5s;
    width: 376px;
  }
  .signature-container {
    margin-left: 2.5%;
  }
  .field-wrapper {
    margin-left: 9%;
  }
  .App-header ul {
    margin-left: 2.5%;
    padding-left: 2.5%;
    width: 90%;
  }
  .App-header h1 {
    padding-left: 0;
  }
  .App-intro h5 {
    width: 50%;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
